/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CompaniesDocument,
  CompanyForHireDocument,
  Job,
  JobContractType,
  JobFilters,
  JobPriority,
  JobsGroupedCountDocument,
  JobsGroupedForCardDocument,
  JobStatus,
  ProjectForEditDocument,
  ProjectsForListDocument,
  RoleKey,
  Skill,
  SkillsDocument,
  SkillsSearchDocument,
  UsersForFilsterListDocument,
} from '@upper/graphql/internal'
import {
  classNames,
  FilterBoolean,
  FilterList,
  Icons,
  SearchFilter,
} from '@upper/ui'
import {
  deserializeFilters,
  formatName,
  omitEmpty,
  serializeFilters,
} from '@upper/utils'
import { NextPageContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useClient, useQuery } from 'urql'
import { useDebounce } from 'use-debounce'
import { capitalize } from 'voca'
import { PageContent } from '../components/page-content'
import { PageHeader } from '../components/page-header'
import { Pagination } from '../components/pagination'
import { JOB_CONTRACT_OPTIONS, JOB_STATUS_OPTIONS } from '../const/job'
import { pageTitle } from '../const/upper'
import { MemoizedJobFragment } from '../fragments/job'
import { MainLayout } from '../fragments/layout/main'
import { usePagination } from '../hooks/use-pagination'

const PrioritySections = [
  JobPriority.High,
  JobPriority.Medium,
  JobPriority.Low,
  JobPriority.None,
].map((k) => k.toLowerCase())

function Page({ filters: filterFromQuery }) {
  const urqlClient = useClient()
  const router = useRouter()

  const [filters, setFilters] = useState<JobFilters>(filterFromQuery)

  const [debouncedFilters] = useDebounce(filters, 300)

  const [jobsCount] = useQuery({
    query: JobsGroupedCountDocument,
    variables: {
      filters: omitEmpty(debouncedFilters),
    },
  })
  const totalJobs = jobsCount.data?.jobsGroupedCount.total || 0
  const { total, ...pagination } = usePagination(totalJobs, 100)
  const [jobsGroupedResult] = useQuery({
    query: JobsGroupedForCardDocument,
    variables: {
      filters: omitEmpty(debouncedFilters),
      limit: pagination.limit,
      offset: pagination.offset,
    },
  })

  const [teamResults] = useQuery({
    query: UsersForFilsterListDocument,
    variables: { filters: { ids: filters.usersByRole?.map((ur) => ur.id) } },
    pause: !filters.usersByRole,
  })

  const teamFilterValue = useMemo(() => {
    if (!teamResults.data?.users && !filters?.usersByRole) return []
    return filters?.usersByRole
      ?.map((ur) => {
        const user = teamResults?.data?.users?.find((u) => u.id === ur.id)
        if (!user) return null
        return {
          id: user.id,
          role: ur.role,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      })
      .filter(Boolean)
  }, [filters?.usersByRole, teamResults.data?.users])

  const [addedByResults] = useQuery({
    query: UsersForFilsterListDocument,
    variables: { filters: { ids: filters.placedByIds } },
    pause: !filters.placedByIds,
  })

  const addedByFilterValue = addedByResults.data?.users

  const handleUpdateFilters = useCallback(
    (partial: Partial<JobFilters>) => {
      setFilters((f) => ({ ...f, ...partial }))
      pagination.reset()
    },
    [pagination]
  )

  const fileterCompanyId = filters.companyIds?.[0]
  const [companyResult] = useQuery({
    query: CompanyForHireDocument,
    variables: { id: fileterCompanyId },
    pause: !fileterCompanyId,
  })
  const companyFilterValue = companyResult.data?.company

  const fileterProjectId = filters.projectIds?.[0]
  const [projectResult] = useQuery({
    query: ProjectForEditDocument,
    variables: { id: fileterProjectId },
    pause: !fileterProjectId,
  })
  const projectFilterValue = projectResult.data?.project

  const [skillsResult] = useQuery({
    query: SkillsDocument,
    variables: { filters: { ids: filters.skills?.[0]?.ids } },
    pause: !filters.skills?.[0]?.ids,
  })
  const skillsFilterValue = skillsResult.data?.skills

  const handleLoadCompanies = useCallback(
    async (v) => {
      const results = await urqlClient
        .query(CompaniesDocument, { filters: { name: v } })
        .toPromise()
      return results.data?.companies
    },
    [urqlClient]
  )

  const handleLoadProjects = useCallback(
    async (v) => {
      const results = await urqlClient
        .query(ProjectsForListDocument, { filters: { name: v } })
        .toPromise()
      return results.data?.projects
    },
    [urqlClient]
  )

  const handleLoadPeople = useCallback(
    async (name) => {
      const roles = [
        RoleKey.Em,
        RoleKey.Sourcer,
        RoleKey.Expert,
        RoleKey.Fellow,
      ]
      const usersResult = await urqlClient
        .query(UsersForFilsterListDocument, {
          filters: {
            name,
            roles,
          },
        })
        .toPromise()
      const users = usersResult.data?.users ?? []
      const groups = roles.map((role) => ({
        label: capitalize(role),
        items: users
          .filter((user) =>
            user.userRoles?.flatMap((ur) => ur.role.key).includes(role)
          )
          .map((u) => ({
            id: u.id,
            firstName: u.firstName,
            lastName: u.lastName,
            role,
          })),
      }))

      return groups
    },
    [urqlClient]
  )

  const handleLoadAddedBy = useCallback(
    async (name) => {
      const usersResult = await urqlClient
        .query(UsersForFilsterListDocument, {
          filters: {
            name,
            roles: [RoleKey.Sourcer],
          },
        })
        .toPromise()
      return usersResult.data?.users
    },
    [urqlClient]
  )

  const handleLoadSkills = useCallback(
    async (name: string) => {
      const result = await urqlClient
        .query(SkillsSearchDocument, { name })
        .toPromise()
      return result.data?.searchSkills || []
    },
    [urqlClient]
  )

  // statuses
  const statusFilterValue = useMemo(
    () =>
      JOB_STATUS_OPTIONS.filter((o) =>
        filters.statuses?.includes(o.value as JobStatus)
      ),
    [filters.statuses]
  )

  // contract
  const contractFilterValue = useMemo(
    () =>
      JOB_CONTRACT_OPTIONS.find((o) =>
        filters.contractTypes?.includes(o.value as JobContractType)
      ),
    [filters.contractTypes]
  )

  // jobs board
  const jobs = useMemo(
    () =>
      jobsGroupedResult.data?.jobsForBoard?.reduce((acc, j) => {
        const priority = String(j.priority || JobPriority.None).toLowerCase()
        if (!acc[priority]) {
          acc[priority] = []
        }
        acc[priority].push(j)
        return acc
      }, {}) ?? {},
    [jobsGroupedResult.data?.jobsForBoard]
  )

  const jobsMarkup = useMemo(
    () =>
      PrioritySections.map((ps) => (
        <div
          key={`job-section-${ps}`}
          className={classNames(
            'block flex-shrink-0 border-opacity-5',
            !jobs[ps] || jobs[ps].length === 0 ? 'w-80' : 'w-600'
          )}
        >
          <header className="mb-3 sticky top-0 py-3 px-4">
            <h4
              className={`text-2xl font-semibold capitalize flex items-start`}
            >
              {ps}
              <span className="ml-3 font-light text-gray text-xs font-mono">
                {jobs[ps] && `(${jobs[ps].length})`}
              </span>
            </h4>
          </header>
          {jobs[ps] &&
            jobs[ps].map((j: Job) => (
              <div className="mb-5" key={j.id}>
                <MemoizedJobFragment
                  data={j}
                  priority={ps}
                  placedByIds={filters?.placedByIds}
                />
              </div>
            ))}
        </div>
      )),
    [filters?.placedByIds, jobs]
  )

  useEffect(() => {
    router.replace(
      {
        query: { filters: serializeFilters(omitEmpty(debouncedFilters)) },
      },
      undefined,
      {
        shallow: true,
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters])

  const loadedJobs = jobsGroupedResult.data?.jobsForBoard?.length

  return (
    <>
      <Head>
        <title>{pageTitle('Jobs')}</title>
      </Head>
      <MainLayout>
        {/* header */}
        <PageHeader
          title="Jobs"
          className="overflow-visible"
          pagination={
            total > 1 && (
              <Pagination
                // infiniteScroll
                total={totalJobs}
                current={loadedJobs}
                limit={pagination.limit}
                page={pagination.page}
                onNext={pagination.onNext}
                onPrev={pagination.onPrev}
              />
            )
          }
        >
          <SearchFilter
            label="Search"
            icon={<Icons.Search />}
            placeholder="Search by role"
            value={filters.name ?? ''}
            onChange={(name) => handleUpdateFilters({ name })}
            onClear={() => handleUpdateFilters({ name: '' })}
          />
          <FilterList
            label="Company"
            icon={<Icons.Company />}
            loadOptions={handleLoadCompanies}
            value={companyFilterValue}
            valueForOption={(o) => o.id}
            labelForOption={(o) => o.name}
            onChange={(o) => {
              handleUpdateFilters({
                companyIds: [o.id],
              })
            }}
            onClear={() => {
              handleUpdateFilters({ companyIds: null })
            }}
            async
          />

          <FilterList
            label="Project"
            icon={<Icons.Project />}
            loadOptions={handleLoadProjects}
            value={projectFilterValue}
            valueForOption={(o) => o.id}
            labelForOption={(o) => o.name}
            onChange={(o) => {
              handleUpdateFilters({
                projectIds: [o.id],
              })
            }}
            onClear={() => {
              handleUpdateFilters({ projectIds: null })
            }}
            async
          />

          <FilterList
            label="Statuses"
            tooltip="Statuses"
            options={JOB_STATUS_OPTIONS}
            value={statusFilterValue}
            allowMultiselect
            icon={<Icons.Status />}
            onChange={(o) => {
              handleUpdateFilters({
                statuses:
                  o?.length > 0
                    ? (o?.map((fo: any) => fo.value) as JobStatus[])
                    : undefined,
              })
            }}
            onClear={() => {
              handleUpdateFilters({
                statuses: null,
              })
            }}
          />
          <FilterList
            label="Contract type"
            tooltip="Contract type"
            options={JOB_CONTRACT_OPTIONS}
            value={contractFilterValue}
            icon={<Icons.ContractType />}
            onChange={(o) => {
              handleUpdateFilters({
                contractTypes: o?.value ? [o.value] : undefined,
              })
            }}
            onClear={() => {
              handleUpdateFilters({
                contractTypes: null,
              })
            }}
          />
          <FilterList
            label="Skills"
            tooltip="Skills"
            allowMultiselect
            icon={<Icons.Skills />}
            loadOptions={handleLoadSkills}
            value={skillsFilterValue}
            valueForOption={(o) => o.id}
            labelForOption={(o) => o.name}
            onChange={(o) => {
              handleUpdateFilters({
                skills: o && [{ ids: (o as Skill[]).map((o: any) => o.id) }],
              })
            }}
            onClear={() => handleUpdateFilters({ skills: null })}
            async
          />
          <FilterList
            label="Team"
            icon={<Icons.Team />}
            loadOptions={handleLoadPeople}
            value={teamFilterValue}
            valueForOption={(o) => `${o.id}:${o.role}`}
            labelForOption={(o) => formatName(o)}
            onChange={(o) => {
              handleUpdateFilters({
                usersByRole:
                  o?.length > 0
                    ? o?.map((s) => ({ id: s.id, role: s.role }))
                    : null,
              })
            }}
            onClear={() => handleUpdateFilters({ usersByRole: null })}
            allowMultiselect
            async
          />
          <FilterList
            label="Placed by"
            icon={<Icons.PrimaryPosition />}
            loadOptions={handleLoadAddedBy}
            value={addedByFilterValue}
            valueForOption={(o) => o.id}
            labelForOption={(o) => formatName(o)}
            onChange={(o) => {
              handleUpdateFilters({
                placedByIds: o?.length > 0 ? [o.id] : null,
              })
            }}
            onClear={() => handleUpdateFilters({ placedByIds: null })}
            async
          />
          <FilterBoolean
            label="Paused Sourcing"
            tooltip="Paused Sourcing"
            icon={<Icons.PausedSourcing />}
            value={filters?.isPausedSourcing}
            onChange={(o) =>
              handleUpdateFilters({
                isPausedSourcing: o,
              })
            }
            onClear={() =>
              handleUpdateFilters({
                isPausedSourcing: undefined,
              })
            }
          />
        </PageHeader>
        {/* content */}
        <PageContent
          className="flex gap-6 overflow-auto z-0 relative px-6 pb-12 min-h-full"
          loading={jobsGroupedResult.fetching}
        >
          {jobsMarkup}
        </PageContent>
      </MainLayout>
    </>
  )
}

Page.authenticate = true

Page.getInitialProps = async ({ query }: NextPageContext) => {
  let filters: any = { statuses: [JobStatus.InStaffing, JobStatus.InReview] }
  if (query.filters) {
    filters = deserializeFilters(query.filters as string)
  }

  return { filters }
}

export default Page
