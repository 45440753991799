import { classNames } from '@upper/ui'

type Props = {
  total?: number
  current?: number
  limit?: number
  page?: number
  infiniteScroll?: boolean
  onNext?: () => void
  onPrev?: () => void
}
export const Pagination = ({
  total = 0,
  current = 0,
  limit = 0,
  page = 0,
  infiniteScroll,
  onNext,
  onPrev,
}: Props) => {
  const totalPages = Math.max(Math.ceil(total / limit), 1)
  const canShowPrev = page > 0 && totalPages > 1
  const canShowNext = page < totalPages - 1
  return (
    <div className="ml-auto flex items-center gap-x-2 flex-none">
      <div className="text-gray text-sm font-mono">
        {!infiniteScroll
          ? `Page ${page + 1} of ${totalPages}`
          : `Loaded ${current}/${total}`}
      </div>
      {!infiniteScroll && (
        <>
          <button
            className={classNames(
              'p-3 rounded-md bg-gray-light hover:bg-white hover:text-blue transition-colors',
              !canShowPrev && 'bg-opacity-60 text-gray pointer-events-none'
            )}
            onClick={onPrev}
          >{`<-`}</button>
          <button
            className={classNames(
              'p-3 rounded-md bg-gray-light hover:bg-white hover:text-blue transition-colors',
              !canShowNext && 'bg-opacity-60 text-gray pointer-events-none'
            )}
            onClick={onNext}
          >{`->`}</button>
        </>
      )}
    </div>
  )
}
