import { useCallback, useState } from 'react'

export const usePagination = (totalEntries: number, initialLimit = 30) => {
  const [limit, setLimit] = useState(initialLimit)
  const [page, setPage] = useState(0)

  const onNext = useCallback(() => {
    if (page * limit < totalEntries) setPage((p) => p + 1)
  }, [limit, page, totalEntries])
  const onPrev = useCallback(() => {
    if (page > 0) setPage((p) => p - 1)
  }, [page])

  const reset = useCallback(() => {
    setPage(0)
  }, [])

  const offset = page * limit
  const total = Math.max(Math.ceil(totalEntries / limit), 1)

  return { limit, page, offset, total, onNext, onPrev, reset }
}
