/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSticky } from '@upper/hooks'
import { classNames } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { useNavigation } from '../hooks/use-navigation'

type Props = {
  title?: string
  className?: string
  titleAddon?: ReactNode
  pagination?: ReactNode
  filtersClassName?: string
}
export const PageHeader = forwardRef<any, PropsWithChildren<Props>>(
  (
    { children, title, titleAddon, pagination, className, filtersClassName },
    ref
  ) => {
    const [stickyRef, isSticky] = useSticky()
    const navigation = useNavigation()
    const paddingLeft = navigation.margin + navigation.paddedSize
    const style: any = {}
    style.transition = 'all 300ms ease'
    style.paddingLeft = paddingLeft

    return (
      <>
        {/* title */}
        <header
          ref={stickyRef}
          className={classNames(
            'text-gray-dark sticky left-0 z-40 space-y-3 px-3 py-3 transition-all',
            isSticky && '-top-[1px] bg-white shadow-sm',
            className
          )}
          style={style}
        >
          <div className="flex items-start gap-6">
            {title && (
              <h2 className="text-gray-darkest py-2 text-2xl font-semibold">
                {title}
              </h2>
            )}
            {titleAddon}
            {pagination}
          </div>
          <div
            className={twMerge('flex flex-wrap gap-3', filtersClassName)}
            ref={ref}
          >
            {children}
          </div>
        </header>
      </>
    )
  }
)

PageHeader.displayName = 'PageHeader'

type PageHeaderTabs = {
  tabs: {
    label: string
    href: string
  }[]
}

export function PageHeaderTabs({ tabs }: PageHeaderTabs) {
  const router = useRouter()

  return (
    <div className="bg-gray-lightest mt-1.5 inline-flex rounded-md">
      {tabs.map((tab, index) => (
        <Link
          href={tab.href}
          key={`tab-${index}`}
          className={classNames(
            'border-gray-light border border-r-0 px-4 py-2 text-sm font-medium transition-all first:rounded-l-md last:rounded-r-md last:border-r',
            router.pathname === tab.href
              ? 'text-blue bg-white'
              : 'text-gray-dark bg-transparent hover:bg-white'
          )}
        >
          {tab.label}
        </Link>
      ))}
    </div>
  )
}
