import { classNames, Tooltip } from '@upper/ui'

export const JobPausedIndicator = () => {
  return (
    <Tooltip content="Sourcing is paused">
      <div
        className={classNames(
          'bg-blue inline-flex h-8 w-8 flex-none items-center justify-center rounded-full p-1 font-mono text-xs'
        )}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.571429V7.42857C0 7.75 0.410156 8 0.875 8H1.75C2.24219 8 2.625 7.75 2.625 7.42857V0.571429C2.625 0.267858 2.24219 0 1.75 0H0.875C0.410156 0 0 0.267858 0 0.571429Z"
            fill="white"
          />
          <path
            d="M5 0.571429V7.42857C5 7.75 5.41016 8 5.875 8H6.75C7.24219 8 7.625 7.75 7.625 7.42857V0.571429C7.625 0.267857 7.24219 0 6.75 0H5.875C5.41016 0 5 0.267857 5 0.571429Z"
            fill="white"
          />
        </svg>
      </div>
    </Tooltip>
  )
}
