/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames, Spinner } from '@upper/ui'
import { createElement, CSSProperties, PropsWithChildren } from 'react'
import { useNavigation } from '../hooks/use-navigation'

type Props = {
  loading?: boolean
  noPadding?: boolean
  className?: string
  style?: CSSProperties
}
export const PageContent = ({
  children,
  noPadding,
  loading,
  className,
  style: initialStyle,
}: PropsWithChildren<Props>) => {
  const navigation = useNavigation()
  const paddingLeft = navigation.margin + navigation.paddedSize
  const style: any = { ...(initialStyle ?? {}) }
  if (!noPadding) {
    style.transition = 'all 300ms ease'
    style.paddingLeft = paddingLeft
  }
  const element = createElement(
    'section',
    {
      style,
      className: classNames('overflow-auto block pr-6 pb-6', className),
    },
    children
  )
  return (
    <>
      {loading && (
        <div
          style={style}
          className="fixed grid content-center justify-center left-0 right-0 bottom-0 top-0 bg-gray-lightest/30 z-[2]"
        >
          <Spinner />
        </div>
      )}
      {element}
    </>
  )
}
