import {
  EngagementsForJobDocument,
  JobContractType,
  JobHealthPerformance,
  JobsGroupedQuery,
  JobStatus,
} from '@upper/graphql/internal'
import { useModals } from '@upper/hooks'
import { EditIcon } from '@upper/icons'
import { Card, classNames, Spinner, Tooltip } from '@upper/ui'
import { formatDate, formatName, pick } from '@upper/utils'
import { XIcon } from 'lucide-react'
import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { twMerge } from 'tailwind-merge'
import { useQuery } from 'urql'
import { EmploymentIcon } from '../components/icons/employment'
import { JobHealthIndicator } from '../components/job/job-health-indicator'
import { JobPausedIndicator } from '../components/job/job-paused-indicator'
import { JobTeam } from '../components/job/job-team'
import { ENGAGEMENT_STATUS_OPTIONS } from '../const/engagement'
import { ModalAction } from '../const/modals'

const STYLE_FOR_HEALTH = {
  red: 'border-2 border-red !bg-[#FCEAE6]',
  yellow: 'border-2 border-yellow-dark !bg-[#FFF8E0]',
  green: 'border-2 border-green !bg-[#D9F7EA]',
  none: 'border-2 border-transparent bg-white',
}

type JobFragmentProps = {
  data: JobsGroupedQuery['jobsGrouped'][0]
  priority?: string
  placedByIds?: string[]
}

export function JobFragment({ data, priority, placedByIds }: JobFragmentProps) {
  const { show } = useModals(ModalAction.EDIT_JOB)
  const [ref, isInView] = useInView({ threshold: 0.75 })

  const [engagementsResult] = useQuery({
    query: EngagementsForJobDocument,
    variables: {
      filters: { job: { ids: [data?.id] }, placedByIds },
      limit: 500,
    },
    pause: !data?.id || !isInView,
  })

  const handleEditJob = useCallback(() => {
    show({ id: data.id, projectId: data.projectId, companyId: data.companyId })
  }, [data, show])

  const engagements = useMemo(
    () => engagementsResult?.data?.engagements ?? [],
    [engagementsResult?.data?.engagements]
  )

  const displayEngagements = useMemo(
    () =>
      engagements.reduce((acc, e) => {
        if (!e) return
        if (!acc[e.latestRevision?.status]) {
          acc[e.latestRevision?.status] = []
        }
        acc[e.latestRevision?.status].push(e)
        return acc
      }, {}),
    [engagements]
  )

  const colors = useMemo(() => {
    if (!data.staffingDeadline) return STYLE_FOR_HEALTH['none']
    if (data.status !== JobStatus.InStaffing) return STYLE_FOR_HEALTH['none']
    if (data.health?.performance === JobHealthPerformance.Mediocre) {
      return STYLE_FOR_HEALTH['yellow']
    } else if (data.health?.performance === JobHealthPerformance.Bad) {
      return STYLE_FOR_HEALTH['red']
    }
    return STYLE_FOR_HEALTH['green']
  }, [data.health?.performance, data.staffingDeadline, data.status])

  return (
    <Card
      padding="light"
      className={classNames(
        'relative box-content overflow-hidden !rounded-2xl !shadow-none',
        colors
      )}
      // style={{ ...colors }}
    >
      {/* loading */}
      {engagementsResult.fetching && (
        <div className="absolute z-30 grid h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {/* on hold overlay */}

      <Card.Section>
        <div style={{ minHeight: 120 }} ref={ref}>
          {/* header */}
          <header className={classNames('-mr-4 flex items-start gap-3')}>
            {(data.status === JobStatus.InStaffing ||
              data.contractType === JobContractType.Employment) && (
              <div className="flex gap-1">
                {data.status === JobStatus.InStaffing && (
                  <>
                    {data.isPausedSourcing && <JobPausedIndicator />}
                    {data.staffingDeadline && (
                      <JobHealthIndicator health={data?.health} />
                    )}
                  </>
                )}
                {data.contractType === JobContractType.Employment && (
                  <Tooltip
                    content={'Employment'}
                    triggerProps={{ asChild: true }}
                  >
                    <div
                      className={classNames(
                        'text-blue inline-flex h-8 w-8 flex-none items-center justify-center rounded-full p-1 font-mono text-xs'
                      )}
                    >
                      <EmploymentIcon className="flex-none" />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            <div className="mr-auto leading-none">
              {/* job name */}
              <h5 className="relative z-20 text-sm font-medium hover:underline">
                <Link href={`/jobs/${data.id}`} className="flex">
                  <span className="block" aria-label="job name">
                    {data.name}
                  </span>
                  <span
                    className="ml-1 block text-black/60"
                    aria-label="job team size"
                    title="Team size"
                  >{` (${data.teamSize})`}</span>
                </Link>
              </h5>
              {/* project and company */}
              {data.company && (
                <p className="flex items-center gap-1">
                  <span className="text-blue text-xs">
                    {data.company?.name}
                  </span>
                  {!data.company?.isVerified && (
                    <span
                      className={twMerge(
                        'rounded-full p-0.5 text-white',
                        !data.company.isVerified && 'bg-slate-600'
                      )}
                    >
                      <XIcon size={10} strokeWidth={1} absoluteStrokeWidth />
                    </span>
                  )}
                </p>
              )}
            </div>
            {/* team */}
            <div className="relative z-10 flex-none text-xs">
              <JobTeam
                jobId={data.id}
                team={pick(
                  data,
                  'em',
                  'expert',
                  'fellow',
                  'jobSourcers',
                  'jobInterviewers'
                )}
                // onChange={handleTeamChange}
              />
            </div>
            {/* edit */}
            <button
              className="text-gray-dark hover:text-gray-darkest hover:bg-yellow z-10 flex h-8 w-8 flex-none items-center justify-center rounded bg-white/50 transition-colors"
              onClick={handleEditJob}
            >
              <EditIcon />
            </button>
          </header>
          <div className="mt-2 flex items-center gap-3">
            {/* project and company */}
            {data.project && (
              <p className="">
                {/* <span className="text-xs text-blue">{data.company.name}</span>{' '} */}
                {/* <span className="text-xs text-gray">—</span>{' '} */}
                <span className="text-gray-dark text-xs">
                  {data.project?.name}
                </span>
              </p>
            )}
            {/* date */}
            <div className="ml-auto flex gap-2">
              {data.daysInStaffing > 0 && (
                <Tooltip content={`Days in staffing: ${data.daysInStaffing}`}>
                  <div
                    className={classNames(
                      'rounded bg-black/5 px-2 py-2 font-mono text-xs tracking-wide text-black/60'
                    )}
                  >
                    DIS: {data.daysInStaffing}
                  </div>
                </Tooltip>
              )}
              {data.staffingDeadline && (
                <Tooltip content="Deadline">
                  <div
                    className={classNames(
                      'rounded bg-white/60 px-2 py-2 font-mono text-xs tracking-wide'
                    )}
                  >
                    D: {formatDate(data.staffingDeadline)}
                  </div>
                </Tooltip>
              )}
              <Tooltip
                content={
                  <span className="font-mono text-xs">
                    {`Created at ${formatDate(data.createdAt)}`}
                  </span>
                }
              >
                <div
                  className={classNames(
                    'rounded px-2 py-2 font-mono text-xs tracking-wide'
                    // dateClassName
                  )}
                >
                  {data.startDate ? formatDate(data.startDate) : '-'}
                </div>
              </Tooltip>
            </div>
          </div>
          {/* skills */}
          {data.requiredSkills && (
            <div className="text-gray-dark mt-3 flex flex-wrap gap-x-2 gap-y-1 whitespace-nowrap font-mono text-xs">
              {data.requiredSkills.map((s) => (
                <span
                  key={s.name}
                  className="rounded-full border border-black/5 bg-white/50 px-3 py-1 leading-none"
                >
                  {s.name}
                </span>
              ))}
            </div>
          )}
          {/* engagement status */}
          <div className="mt-3 flex flex-wrap gap-2">
            {ENGAGEMENT_STATUS_OPTIONS.map((eso) => {
              const engagements = displayEngagements[eso.value]
              const hasEngagements = engagements?.length > 0
              return (
                hasEngagements && (
                  <Tooltip
                    key={eso.value}
                    content={
                      <div
                        className={classNames(
                          'grid gap-x-2',
                          engagements.length > 1 && 'w-64 grid-cols-2'
                        )}
                      >
                        {engagements
                          .sort((ea, eb) =>
                            ea.talent.lastName?.toUpperCase() >
                            eb.talent.lastName?.toUpperCase()
                              ? 1
                              : -1
                          )
                          .map((e) => (
                            <span
                              key={e.id}
                              className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap p-1 text-xs"
                            >
                              {formatName(e.talent)}
                            </span>
                          ))}
                      </div>
                    }
                    contentProps={{ side: 'top', align: 'center' }}
                    delayDuration={100}
                  >
                    <div
                      className={classNames(
                        'bg-gray-dark/10 text-gray-dark rounded bg-opacity-20 px-2 py-1 text-xs',
                        !hasEngagements && 'text-gray'
                      )}
                    >
                      {eso.label}{' '}
                      <span
                        className={classNames(
                          '',
                          hasEngagements && 'text-blue'
                        )}
                      >
                        ({engagements.length})
                      </span>
                    </div>
                  </Tooltip>
                )
              )
            })}
          </div>
        </div>
      </Card.Section>
    </Card>
  )
}

export const MemoizedJobFragment = React.memo(JobFragment)
